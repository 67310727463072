@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/zindexes.module' as *;

.root {
  z-index: 1;
  position: relative;
  display: inline-block;
  background: none;
  border: none;
  padding: pxToRem(12);
  margin: pxToRem(-12);
  vertical-align: middle;
  border-radius: 50%;
  width: pxToRem(40);
  height: pxToRem(40);

  .icon {
    display: block;
    font-size: pxToRem(16);
  }
}

.tooltip {
  z-index: $zIndex-modal;
  background: var(--for-neu-inv);
  color: var(--tex-inv-tie1);
  box-shadow: var(--elevation-12dp);
  padding: pxToRem(8) pxToRem(12);
  border-radius: var(--cor_rad-md);
  max-width: pxToRem(240);

  .title {
    margin-bottom: pxToRem(8);
  }
}

.tooltipTitle,
.tooltipDescription {
  @include fontSize(14, 18);
}
